window.ideenplanetost = {
	activeAnimationSheet: {
		fadeOut: true,
		hasInternalTransform: false,
		id: null,
		passPosition: 0,
		passDuration: 0,
		passPositionAbsolute: 0,
		passDurationAbsolute: 0,
		siblingAfter: null,
		siblingBefore: null
	},
	animationSheets: [],
	commonSettings: {
		autoScrollToFirstSheet: if autoScrollToFirstSheet then autoScrollToFirstSheet else false,
		betaState: if betaState then betaState else false,
		blendElements: if blendElements then blendElements else false,
		blendElementsStart: .8,
		circle3dMap: {
			settings: {
				animationDegreeSteps: .2,
			},
			front: {
				x: 0,
				y: 30,
				z: 20
			},
			back: {
				x: 0,
				y: -60,
				z: -15
			},
			left: {
				x: -40,
				y: 0,
				z: 0
			},
			right: {
				x: 40,
				y: 0,
				z: 0
			}
		},
		mobileBp:	768
	},
	components: [],
	firstTouch: false,
	ids: [],
	overlay: {
		actualOpen: undefined,
		overwritten: {
			id: undefined,
			type: undefined
		}
	},
	pageInit: {
		event: undefined,
		loadCount: 0,
		loaded: 0
	},
	pageLoader: {
		animation: undefined,
		frequency: 0,
		frequencyDirection: 'up',
		frequencyMax: .2,
		frequencyStart: .1,
		frequencyStep: .01,
		tick: 50
	},
	pageProgress: {
		animation: undefined,
		frequency: 0,
		frequencyDirection: 'up',
		frequencyMax: .1,
		frequencyStart: .01,
		frequencyStep: .01,
		tick: 50
	},
	pageScroll: {
		event: undefined,
		firstSheetShown: false,
		lastScroll: undefined,
		lingering: if animationLingering then animationLingering else false,
		maxScroll: window.innerHeight,
		maxScrollHeights: 1,
		mobileScrollFactor: .5,
		naturalMobileScrolling: true,
		scrolled: 0,
		simplebar: undefined,
		touchStartY: 0
	},
	pageTransform: {
		event: undefined,
		x: 0,
		y: 0,
		maxPercentage: 10
	},
	scrollDownHint: {
		checker: undefined,
		delay: 5000,
		finished: false,
		lastScroll: undefined
	}
}
app = window.ideenplanetost

# #########
# HELPERS
###########

# Check URL Parameters
app.CheckURLParams = ->
	url = window.location.href
	queryStart = url.indexOf("?") + 1
	queryEnd   = url.indexOf("#") + 1 || url.length + 1
	query = url.slice(queryStart, queryEnd - 1)
	pairs = query.replace(/\+/g, " ").split("&")
	parms = {}

	if (query == url || query == "") 
		return

	for i in [0..pairs.length-1]
		nv = pairs[i].split("=", 2)
		n = decodeURIComponent(nv[0])
		v = decodeURIComponent(nv[1])

		if (!parms.hasOwnProperty(n))
			parms[n] = []
		parms[n].push(if nv.length == 2 then v else null)

	return parms

# Cookies
app.GetCookie = (name) ->
	name = name + "="
	cookiearray = decodeURIComponent(document.cookie).split(';')
	for cookie in cookiearray
		while cookie.charAt(0) == ' '
			cookie = cookie.substring(1)
		if cookie.indexOf(name) == 0
			return cookie.substring(name.length, cookie.length)
	return ""
app.CreateCookie = (name, value, expdays, update) ->
	if (app.GetCookie(name) != "") && !update
		return false
	else
		if expdays != null || ''
			expdate = new Date()
			expdate.setTime(expdate.getTime() + (expdays*24*60*60*1000))
			document.cookie = name + "=" + value + ";expires="+ expdate.toUTCString() + ";path=/"
		else
			document.cookie = name + "=" + value + ";path=/"
	return true
app.DeleteCookie = (name) ->
	if app.GetCookie(name) == ""
		return false
	else
		document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
	return true

# Copy Text
app.CopyText = (text) ->
	copyDummy = document.createElement "textarea"
	document.body.appendChild copyDummy
	copyDummy.setAttribute "id", "copy-dummy_id"
	document.getElementById("copy-dummy_id").value = text
	copyDummy.select()
	document.execCommand "copy"
	document.body.removeChild copyDummy
	return

# Cumulative Offset
app.CumulativeOffset = (elem) ->
	top = 0
	left = 0
	if elem
		while true
			top += elem.offsetTop  || 0
			left += elem.offsetLeft || 0
			elem = elem.offsetParent

			break unless elem

	return {
		top: top,
		left: left
	}

# Degree to Viewport Position
app.DegreeToTransform = (degree) ->
	degree = if degree < 0 then 0 else if degree > 360 then 360 else degree
	index = 1
	# front -> right
	if degree >= 0 && degree <= 90
		x = (app.commonSettings.circle3dMap.right.x / 90) * degree
		y = app.commonSettings.circle3dMap.front.y - ((app.commonSettings.circle3dMap.front.y / 90) * degree)
		z = app.commonSettings.circle3dMap.front.z - ((app.commonSettings.circle3dMap.front.z / 90) * degree)
	# right -> back
	if degree > 90 && degree <= 180
		x = app.commonSettings.circle3dMap.right.x - ((app.commonSettings.circle3dMap.right.x / 90) * (degree - 90))
		y = (app.commonSettings.circle3dMap.back.y / 90) * (degree - 90)
		z = (app.commonSettings.circle3dMap.back.z / 90) * (degree - 90)
		index = -1
	# back -> left
	if degree > 180 && degree <= 270
		x = (app.commonSettings.circle3dMap.left.x / 90) * (degree - 180)
		y = app.commonSettings.circle3dMap.back.y - ((app.commonSettings.circle3dMap.back.y / 90) * (degree - 180))
		z = app.commonSettings.circle3dMap.back.z - ((app.commonSettings.circle3dMap.back.z / 90) * (degree - 180))
		index = -1
	# left -> front
	if degree > 270 && degree <= 360
		x = app.commonSettings.circle3dMap.left.x - ((app.commonSettings.circle3dMap.left.x / 90) * (degree - 270))
		y = (app.commonSettings.circle3dMap.front.y / 90) * (degree - 270)
		z = (app.commonSettings.circle3dMap.front.z / 90) * (degree - 270)
	transform = "translate3d(" + x + "vw," + y + "vh," + z + "vw)"
	return { transform: transform, index: index }

# Element-In-Viewport
app.ElementInViewport = (elem, offsetTop, offsetBot, absolute) ->
	if elem
		offsetTop = if offsetTop then offsetTop else 0
		elemTop = if absolute then app.CumulativeOffset(elem).top else elem.offsetTop
		return elemTop + offsetTop <= window.pageYOffset + window.innerHeight && elemTop + elem.offsetHeight >= window.pageYOffset
	return false

# Has Class
app.HasClass = (elem, className) ->
	return new RegExp(' ' + className + ' ').test(' ' + elem.className + ' ')

# ID Maker
app.IdMaker = (el, prefix) ->
	# if id exists, return it...
	if el.id != ""
		return el.id
	# ...otherwise create id based by hash on actual time
	else
		return prefix + (+new Date).toString(36)

app.SetId = (el, prefix) ->
	id = app.IdMaker el, prefix
	while app.ids.indexOf(id) > -1
		id = app.IdMaker el, prefix
	app.ids.push id
	el.id = id
	return id

# Random Number From Interval
app.RandomNumberFromInterval = (min, max, integer) ->
	if integer
		number = Math.floor(Math.random() * (max - min + 1) + min)
	else
		number = Math.random() * (max - min) + min
	return number

# Trigger Event
app.TriggerEvent = (elem, eventName) ->
	elem.dispatchEvent new Event(eventName, {'bubbles': true})
	return

# #########
# COMMON
###########

# -- Animation Sheets --
app.AnimationSheetsBinder = ->
	passPosition = 0
	asElemsList = document.getElementById('content').querySelectorAll '.animation-sheet'

	# generate animationSheet objects
	for asElem in asElemsList
		id = app.SetId asElem, 'as_'
		asElem.id = id
		visible = false
		animationPass = parseInt asElem.dataset.animationpass, 10
		if passPosition == 0
			visible = true
		animationSheet = {
			additionalIntervals: [],
			fadeOut: app.HasClass(asElem,'opacity-fade-out'),
			passDuration: animationPass,
			passPosition: passPosition,
			siblingAfter: null,
			siblingBefore: null,
			visible: visible
		}
		app.animationSheets[id] = animationSheet
		if visible
			asElem.classList.add 'visible'
		
		if app.commonSettings.blendElements && passPosition > 0
			asElem.classList.add 'blend-element'

		passPosition += animationPass

	# calculate max scroll heights
	app.pageScroll.maxScrollHeights = passPosition + 1
	app.pageScroll.maxScroll = app.pageScroll.maxScrollHeights * window.innerHeight


	# complete animationSheets attributes
	for asElem in asElemsList
		animationSheet = app.animationSheets[asElem.id]
		prevSibling = asElem.previousElementSibling
		if prevSibling
			if app.HasClass prevSibling, 'animation-sheet'
				animationSheet.siblingBefore = prevSibling.id
		nextSibling = asElem.nextElementSibling
		if nextSibling
			if app.HasClass nextSibling, 'animation-sheet'
				animationSheet.siblingAfter = nextSibling.id

		if animationSheet.visible
			app.UpdateActiveAnimationSheet asElem.id

	# animate sheets by scroll
	if asElemsList.length > 0
		iaElem = document.getElementById 'content_interaction-area'
		iaElem.addEventListener 'pagescroll', (e) ->
			vh = window.innerHeight
			aas = app.activeAnimationSheet

			# check active animation sheet and update otherwise
			if app.pageScroll.scrolled < aas.passPositionAbsolute
				if aas.siblingBefore
					app.UpdateActiveAnimationSheet aas.siblingBefore, "before"
			if app.pageScroll.scrolled > (aas.passPositionAbsolute + aas.passDurationAbsolute)
				if aas.siblingAfter
					app.UpdateActiveAnimationSheet aas.siblingAfter, "after"

			# refresh aas
			aas = app.activeAnimationSheet
			aasElem = document.getElementById(aas.id)
			relativeScrolled = app.pageScroll.scrolled - (aas.passPosition * window.innerHeight)
			proportionalScrolled = relativeScrolled / (aas.passDuration * window.innerHeight)
			isFirstElem = if aas.siblingBefore then false else true
			isLastElem = if aas.siblingAfter then false else true

			if isFirstElem && !app.pageScroll.firstSheetShown && proportionalScrolled > .5
				app.pageScroll.firstSheetShown = true

			if !aas.hasInternalTransform

				# change transform and opacity
				#if !(isFirstElem && app.pageScroll.firstSheetShown)
				if proportionalScrolled < .5
					opacity = proportionalScrolled * 2
					opacity = if app.HasClass(aasElem, 'blend-element') then opacity + .1 else opacity
					scale = opacity
					scale = if scale > 1 then 1 else scale
				
				if !isLastElem
					shrinkStart = if app.pageScroll.lingering then .8 else .5
					if proportionalScrolled > shrinkStart
						opacity = if aas.fadeOut then ( (proportionalScrolled - 1) * -2 ) else 1
						scale = proportionalScrolled * 2 + ((proportionalScrolled - shrinkStart) * 2)
					
					if proportionalScrolled >= app.commonSettings.blendElementsStart
						if aas.siblingAfter
							nextAasElem = document.getElementById aas.siblingAfter
							nextAasElem.classList.add 'visible'
					if proportionalScrolled < app.commonSettings.blendElementsStart
						if aas.siblingAfter
							nextAasElem = document.getElementById aas.siblingAfter
							nextAasElem.classList.remove 'visible'

				aasElem.style.transform = "scale3d(" + scale + "," + scale + "," + scale + ")"
				aasElem.style.opacity = opacity

				# set/unset interactivity
				if proportionalScrolled >= .3 && proportionalScrolled <= .85
					aasElem.classList.add 'interactive'
				else
					if !isLastElem || proportionalScrolled < .3
						aasElem.classList.remove 'interactive'

			return

	return
app.UpdateActiveAnimationSheet = (id, direction) ->
	animationSheet = app.animationSheets[id]
	if animationSheet
		# remove visibilty of old one
		oldActiveAnimationSheet = document.getElementById(app.activeAnimationSheet.id)
		if oldActiveAnimationSheet
			app.animationSheets[oldActiveAnimationSheet.id].visible = false
			if app.HasClass oldActiveAnimationSheet, 'animated-agencies'
				oldActiveAnimationSheet.style.display = ""

			oldActiveAnimationSheet.classList.remove 'visible'

		newActiveAnimationSheetElem = document.getElementById(id)

		# set new animation sheet
		app.activeAnimationSheet.fadeOut = animationSheet.fadeOut
		app.activeAnimationSheet.hasInternalTransform = app.HasClass newActiveAnimationSheetElem, 'internal-transform'
		app.activeAnimationSheet.id = id
		app.activeAnimationSheet.passPosition = animationSheet.passPosition
		app.activeAnimationSheet.passDuration = animationSheet.passDuration
		app.activeAnimationSheet.passPositionAbsolute = animationSheet.passPosition * window.innerHeight
		app.activeAnimationSheet.passDurationAbsolute = animationSheet.passDuration * window.innerHeight
		app.activeAnimationSheet.siblingAfter = animationSheet.siblingAfter
		app.activeAnimationSheet.siblingBefore = animationSheet.siblingBefore

		if app.HasClass newActiveAnimationSheetElem, 'animated-agencies'
			newActiveAnimationSheetElem.style.display = "block"
			if direction == "before"
				newActiveAnimationSheetElem.classList.add 'revert'
			else
				newActiveAnimationSheetElem.classList.remove 'revert'

		animationSheet.visible = true
		# window.setTimeout ->
		# 	newActiveAnimationSheetElem.classList.add 'visible'
		# , 100
		newActiveAnimationSheetElem.classList.add 'visible'
	return

# -- Check for Auto Open Overlay
app.CheckForAutoOpenOverlay = ->
	params = app.CheckURLParams()
	
	if params
		type = app.CheckOverlayParamType params

		# look for overlays to open at start
		if type == 'overlay'
			app.GetContentAndOpenOverlay params.overlay

		# look for agency overlays to open at start
		if type == 'agency'
			app.GetAgencyDetailAndOpenOverlay params.agency

		# look for case overlays to open at start
		if type == 'case'
			app.GetCaseDetailAndOpenOverlay params.case
	return
app.CheckOverlayParamType = (params) ->
	type = null
	if params
		# standard overlays
		if params.hasOwnProperty('overlay')
			if params.offer != ""
				type = 'overlay'
		# agency overlays
		if params.hasOwnProperty('agency')
			if params.agency != ""
				type = 'agency'
		# case overlays
		if params.hasOwnProperty('case')
			if params.case != ""
				type = 'case'
	return type

# -- Initialize Page --
app.InitPage = ->
	app.ShowPageProgress "Urknall wird ausgelöst"

	app.pageInit.event = new Event('pageintialized')

	siteElem = document.getElementById('site')
	siteElem.addEventListener 'pageintialized', (e) ->
		app.HidePageProgress()

		if app.commonSettings.autoScrollToFirstSheet
			app.ScrollToAnimationSheet Object.keys(app.animationSheets)[0]

		return

	loadElemsList = document.getElementById('content').querySelectorAll '.animation-assets img, .animated-headline-motive_content_motive img'
	for loadElem in loadElemsList
		if !loadElem.complete
			app.pageInit.loadCount++
			loadElem.onload = ->
				
				app.pageInit.loaded++
				app.UpdatePageProgress document.getElementById('page-progress').querySelector('.progress'), ((app.pageInit.loaded / app.pageInit.loadCount) * 100)
				if app.pageInit.loaded == app.pageInit.loadCount
					siteElem = document.getElementById('site')
					siteElem.dispatchEvent app.pageInit.event
				return

	# if nothing to load -> finish init and fire event
	if loadElemsList.length < 1 || app.pageInit.loadCount < 1
		siteElem.dispatchEvent app.pageInit.event

	return

# -- Interaction Area --
app.InteractionAreaBinder = ->
	iaElem = document.getElementById 'content_interaction-area'
	iaScrollElem = document.getElementById 'content_interaction-area_scroll'
	siteElem = document.getElementById 'site'

	iaScrollElem.querySelector('#content_interaction-area_scroll_duration').style.height = (app.pageScroll.maxScrollHeights * 100) + 'vh'

	app.pageScroll.simplebar = new SimpleBar(iaScrollElem)
	app.pageScroll.event = new Event('pagescroll')
	app.pageScroll.simplebar.getScrollElement().addEventListener 'scroll', (e) ->
		e.preventDefault()
		if !app.HasClass this, 'overlay-open'
			app.UpdatePageScroll e.target.scrollTop
		
		return

	app.pageTransform.event = new Event('pagetransform')
	iaElem.addEventListener 'mousemove', (e) ->
		if !app.firstTouch
			midX = Math.round(window.innerWidth / 2)
			midY = Math.round(window.innerHeight / 2)
			x = e.clientX - midX
			y = e.clientY - midY
			app.pageTransform.x = x / (midX / app.pageTransform.maxPercentage)
			app.pageTransform.y = y / (midY / app.pageTransform.maxPercentage)
			this.dispatchEvent app.pageTransform.event
		return

	window.addEventListener 'touchstart', (e) ->
		if !app.firstTouch
			dmrElem = document.createElement 'button'
			dmrElem.id = "device-motion-request"
			document.getElementById('site').appendChild dmrElem
			dmrElem.addEventListener 'click', (e) ->
				app.RequestAndBindDeviceMotion()
				return
			app.firstTouch = true

			window.setTimeout ->
				dmrElem.click()
				dmrElem.remove()
				return
			, 100
		return
	
	return
app.UpdatePageScroll = (scrolled) ->
	iaElem = document.getElementById 'content_interaction-area'
	app.pageScroll.lastScroll = new Date()

	scrolled = if scrolled <= 0 then 0 else scrolled
	scrolled = if scrolled >= app.pageScroll.maxScroll then app.pageScroll.maxScroll else scrolled

	app.pageScroll.scrolled = scrolled

	iaElem.dispatchEvent app.pageScroll.event
	return
app.RequestAndBindDeviceMotion = ->
	if typeof(DeviceMotionEvent) != 'undefined' && typeof(DeviceMotionEvent.requestPermission) == 'function'
		DeviceMotionEvent.requestPermission()
		.then (response) ->
			if response == 'granted'
				window.addEventListener 'devicemotion', (e) ->
					midX = Math.round(window.innerWidth / 2)
					midY = Math.round(window.innerHeight / 2)
					rotationX = 0
					rotationY = 0
					if e.alpha >= 270 || e.alpha <= 90
						rotationX = if e.alpha >= 270 then ((360 - e.alpha) * (-1)) else e.alpha
					if e.beta >= 0 && e.beta <= 180
						rotationY = if e.beta > 90 then ((e.beta - 90) * (-1)) else e.beta
					posX = ((rotationX / 90) * midX) + midX
					posY = ((rotationY / 90) * midY) + midY

					app.pageTransform.x = posX / (midX / app.pageTransform.maxPercentage)
					app.pageTransform.y = posY / (midY / app.pageTransform.maxPercentage)

					document.getElementById('content_interaction-area').dispatchEvent app.pageTransform.event
					return
		.catch (error) ->
			console.error error
	return

# -- Scroll To Animation Sheet
app.ScrollToAnimationSheet = (sheetId) ->
	if sheetId
		asElem = document.getElementById sheetId
		if asElem
			asPosition = (app.animationSheets[sheetId].passPosition + (app.animationSheets[sheetId].passDuration / 2 - ( if app.pageScroll.lingering then 0 else .1 )) ) * window.innerHeight
			app.pageScroll.simplebar.getScrollElement().scrollTo {
				top: asPosition, 
				left: 0,
				behavior: "smooth" 
			}
			#app.pageScroll.simplebar.getScrollElement().scrollTop = asPosition
			#console.log "SCROLLED TO SHEET", sheetId, " at calculated position ",asPosition," with vh position", app.animationSheets[sheetId].passPosition," + duration in vh of ", app.animationSheets[sheetId].passDuration
	return

# -- Sound Control --
app.SoundButtonBinder = ->
	soundButtonElem = document.getElementById('content_sound-button')
	if soundButtonElem
		soundState = window.localStorage.getItem('de.ideenplanet-ost.sound-state')
		soundButtonElem.querySelector('.round-icon-button').addEventListener 'click', (e) ->
			e.preventDefault()
			this.classList.toggle 'active'
			bgrdAudioElem = document.getElementById 'content_background-sound'
			if bgrdAudioElem
				if app.HasClass this, 'active'
					bgrdAudioElem.querySelector('audio').pause()
					window.localStorage.setItem 'de.ideenplanet-ost.sound-state', 'off'
				else
					bgrdAudioElem.querySelector('audio').play()
					window.localStorage.setItem 'de.ideenplanet-ost.sound-state', 'on'
			return
		bgrdAudioElem = document.getElementById 'content_background-sound'
		if soundState
			if soundState == "off"
				soundButtonElem.querySelector('.round-icon-button').classList.add 'active'
				bgrdAudioElem.querySelector('audio').pause()
			else
				app.SoundButtonAutoplayChecker()
		else
			window.localStorage.setItem 'de.ideenplanet-ost.sound-state', 'on'
			app.SoundButtonAutoplayChecker()	
	return
app.SoundButtonAutoplayChecker = ->
	soundButtonElem = document.getElementById('content_sound-button')
	bgrdAudioElem = document.getElementById 'content_background-sound'
	soundButtonElem.querySelector('.round-icon-button').classList.add 'highlight'
	soundButtonElem.querySelector('.round-icon-button').classList.add 'active'
	if bgrdAudioElem.querySelector('audio').paused
		window.localStorage.setItem 'de.ideenplanet-ost.sound-state', 'off'
		bgrdAudioElem.querySelector('audio').onplay = ->
			window.localStorage.setItem 'de.ideenplanet-ost.sound-state', 'on'
			soundButtonElem = document.getElementById('content_sound-button')
			soundButtonElem.querySelector('.round-icon-button').classList.remove 'highlight'
			soundButtonElem.querySelector('.round-icon-button').classList.remove 'active'
			return	
	return


# -- Error Outputs --
app.ShowElementError = (element, errorText) ->
	formRow = element.closest '.form-row'
	if formRow
		formRow.classList.add 'error'
		formRow.querySelector('.form-row_error').innerHTML = errorText
	else
		element.classList.add 'error'
	return
app.HideElementError = (element) ->
	formRow = element.closest '.form-row'
	if formRow
		formRow.classList.remove 'error'
		formRow.querySelector('.form-row_error').innerHTML = ""
	else
		element.classList.remove 'error'
	return

# -- Overlay Opener --
app.OverlayOpenerBinder = (onlyOverlay) ->
	if onlyOverlay
		overlayOpenerElemsList = document.getElementById('overlays').querySelectorAll '.overlay-opener, .case-opener'
	else
		overlayOpenerElemsList = document.querySelectorAll '.agency-opener, .case-opener, .overlay-opener'
	for overlayOpenerElem in overlayOpenerElemsList
		overlayOpenerElem.addEventListener 'click', (e) ->
			e.preventDefault()
			# check of overlay is already open
			if app.HasClass document.getElementById('site'), 'overlay-open'
				params = app.CheckURLParams()
				type = app.CheckOverlayParamType params
				if type
					app.overlay.overwritten.type = type
					app.overlay.overwritten.id = params[type][0]

			if app.HasClass this, 'agency-opener'
				app.GetAgencyDetailAndOpenOverlay this.dataset.agencyId
			if app.HasClass this, 'case-opener'
				app.GetCaseDetailAndOpenOverlay this.dataset.caseId
			if app.HasClass this, 'overlay-opener'
				app.GetContentAndOpenOverlay this.dataset.overlayId
			return
	return

# -- Window History Binder --
app.WindowHistoryBinder = ->
	window.addEventListener 'popstate', (e) ->
		if e.state
			app.CheckForAutoOpenOverlay()
		return
	return


# #########
# ATOMS
# #########

# -- Animation Assets --
app.AnimationAssetsBinder = ->
	aaElemsList = document.getElementById('content').querySelectorAll '.animation-asset'
	for aaElem in aaElemsList
		xTransform = if aaElem.dataset.xTransform then parseInt(aaElem.dataset.xTransform,10) else app.RandomNumberFromInterval(-50,50,true)
		yTransform = if aaElem.dataset.yTransform then parseInt(aaElem.dataset.yTransform,10) else app.RandomNumberFromInterval(-50,50,true)
		sizeTransform = if aaElem.dataset.sizeTransform then parseInt(aaElem.dataset.sizeTransform,10) else app.RandomNumberFromInterval(1,50,true)

		aaElem.setAttribute 'data-x-transform', xTransform
		aaElem.setAttribute 'data-y-transform', yTransform
		aaElem.setAttribute 'data-size-transform', sizeTransform

		aaElem.style.transform = "translate(" + xTransform + "vw," + yTransform + "vh)"
		aaElem.style.transition = "margin " + app.RandomNumberFromInterval(.1,1) + "s ease"
		if window.innerWidth > window.innerHeight
			aaElem.style.width = sizeTransform + "vw"
			aaElem.style.height = "auto"
		else
			aaElem.style.height = sizeTransform + "vh"
			aaElem.style.width = "auto"

	if aaElemsList.length > 0
		iaElem = document.getElementById 'content_interaction-area'
		iaElem.addEventListener 'pagetransform', (e) ->
			for activeAaElem in document.getElementById('content').querySelectorAll '.animation-sheet.visible .animation-asset'
				if Math.random() > .5
					activeAaElem.style.marginLeft = (app.pageTransform.x * (-1)) + '%'
					activeAaElem.style.marginTop = (app.pageTransform.y * (-1)) + '%'
			return
	return

# -- Checkbox --
app.CheckboxBinder = (onlyOverlay) ->
	if onlyOverlay
		cbElemsList = document.getElementById('overlays').querySelectorAll '.checkbox'
	else
		cbElemsList = document.querySelectorAll '.checkbox'
	for cbElem in cbElemsList
		cbElem.querySelector('input[type=checkbox]').addEventListener 'change', (e) ->
			app.HideElementError this
			if this.hasAttribute 'required'
				if !this.checked
					app.ShowElementError this, 'Dieses Feld muss ausgewählt werden.'
			return
	return

# -- Image --
app.ImageBinder = (onlyOverlay) ->
	if onlyOverlay
		imagesList = document.getElementById('overlays').querySelectorAll '.image'
	else
		imagesList = document.querySelectorAll '.image'
	for image in imagesList
		if image.dataset.copyright
			if image.dataset.copyright != "" && !app.HasClass(image,'hide-copyright')
				image.dataset.copyright = "© " + image.dataset.copyright
				image.classList.add 'show-copyright'
	return

# -- Input --
app.InputBinder = (onlyOverlay) ->
	if onlyOverlay
		inputElemsList = document.getElementById('overlays').querySelectorAll '.input'
	else
		inputElemsList = document.querySelectorAll '.input'
	for inputElem in inputElemsList
		if inputElem.querySelector('textarea')
			inputElem.querySelector('span.textarea').addEventListener 'input', (e) ->
				app.HideElementError this
				this.closest('.input').querySelector('textarea').value = this.innerText
				if this.innerText != ""
					this.classList.add 'filled'
				else
					if this.closest('.input').querySelector('textarea').hasAttribute 'required'
						app.ShowElementError this, "Dieses Feld muss ausgefüllt werden."
					this.classList.remove 'filled'
				return
		else
			inputElem.querySelector('input:not(input[type=hidden])').addEventListener 'input', (e) ->
				app.HideElementError this
				if this.type == "file"
					if this.value != ""
						
						if this.closest('.input').querySelector('input[type=hidden]')
							maxFileSize = parseInt(this.closest('.input').querySelector('input[type=hidden]').value,10)
						fileNames = []
						for file in this.files
							if maxFileSize
								if file.size <= maxFileSize
									fileNames.push file.name
								else
									this.value = ""
									app.ShowElementError this, "Die Datei konnte nicht hinzugefügt werden, da sie zu groß ist."
							else
								fileNames.push file.name
						this.closest('.input').querySelector('.input_input').innerHTML = fileNames.join(', ')
					else
						this.closest('.input').querySelector('.input_input').innerHTML = ""
				if this.value != ""
					this.classList.add 'filled'
				else
					if this.hasAttribute 'required'
						if this.type != "file"
							app.ShowElementError this, "Dieses Feld muss ausgefüllt werden."
					this.classList.remove 'filled'
				return
		if inputElem.querySelector('.input_input')
			inputElem.querySelector('.input_input').addEventListener 'click', (e) ->
				this.closest('.input').querySelector('input[type=file]').click()
				return
	return

# -- Radio-Group --
app.RadioGroupBinder = (onlyOverlay) ->
	if onlyOverlay
		radioGroupElemsList = document.getElementById('overlays').querySelectorAll('.radio-group')
	else
		radioGroupElemsList = document.querySelectorAll('.radio-group')
	for radioGroupElem in radioGroupElemsList
		for radioInput in radioGroupElem.querySelectorAll('input[type=radio]')
			radioInput.addEventListener 'change', (e) ->
				app.RadioGroupValidate this.closest('.radio-group')
			return
	return
app.RadioGroupValidate = (radioGroupElem) ->
	app.HideElementError radioGroupElem
	if app.HasClass radioGroupElem, 'required'
		if !radioGroupElem.querySelector('input[type=radio]:checked')
			app.ShowElementError radioGroupElem, 'Hier muss eine Option gewählt werden.'
	return

# -- Select --
app.SelectBinder = (onlyOverlay) ->
	if onlyOverlay
		selectElemsList = document.getElementById('overlays').querySelectorAll('.select')
	else
		selectElemsList = document.querySelectorAll('.select')
	if selectElemsList.length > 0
		document.addEventListener 'click', (e) ->
			for selectElem in document.querySelectorAll('.select.ready-to-close')
				selectElem.classList.remove 'open'
			return
	for selectElem in selectElemsList
		selectElem.addEventListener 'click', (e) ->
			this.classList.toggle 'open'
			return
		selectElem.addEventListener 'mouseleave', (e) ->
			this.classList.add 'ready-to-close'
			return
		selectElem.addEventListener 'mouseenter', (e) ->
			this.classList.remove 'ready-to-close'
			return
		if !app.HasClass(selectElem, 'no-input')
			for selectOption in selectElem.querySelectorAll('.select_content_option')
				selectOption.addEventListener 'click', (e) ->
					this.closest('.select').classList.add 'selected'
					this.closest('.select').querySelector('.select_box_title').innerHTML = this.innerHTML
					this.closest('.select').querySelector('input[type=hidden]').value = this.dataset.option
					if app.HasClass selectElem, 'required'
						app.HideElementError selectElem
					return
	return

# -- Page Loader --
app.ShowPageLoader = ->
	document.getElementById('site').classList.add 'loader-shown'
	app.pageLoader.frequency = app.pageLoader.frequencyStart
	app.pageLoader.animation = window.setInterval ->
		if app.pageLoader.frequencyDirection == "up"
			app.pageLoader.frequency += app.pageLoader.frequencyStep
		else
			app.pageLoader.frequency -= app.pageLoader.frequencyStep
		if app.pageLoader.frequency >= app.pageLoader.frequencyMax
			app.pageLoader.frequencyDirection = "down"
		if app.pageLoader.frequency <= app.pageLoader.frequencyStart
			app.pageLoader.frequencyDirection = "up"
		document.getElementById('page-loader').querySelector('.page-loader_filter feTurbulence').setAttribute 'baseFrequency', app.pageLoader.frequency
		return
	, app.pageLoader.tick
	document.getElementById('page-loader').classList.add 'page-loader-shown'
	return

app.HidePageLoader = ->
	document.getElementById('page-loader').classList.remove 'page-loader-shown'
	document.getElementById('site').classList.remove 'loader-shown'
	window.clearInterval app.pageLoader.animation
	return

# -- Page Progress --
app.ShowPageProgress = (message, percent) ->
	document.getElementById('site').classList.add 'progress-shown'
	pageProgressElem = document.getElementById('page-progress')
	message = if message then message else ""
	percent = if percent then percent else 0
	app.UpdatePageProgress pageProgressElem.querySelector('.progress'), percent
	pageProgressElem.querySelector('.progress_message').innerHTML = message
	app.pageProgress.frequency = app.pageProgress.frequencyStart
	app.pageProgress.animation = window.setInterval ->
		pageProgressElem = document.getElementById('page-progress')
		if app.pageProgress.frequencyDirection == "up"
			app.pageProgress.frequency += app.pageProgress.frequencyStep
		else
			app.pageProgress.frequency -= app.pageProgress.frequencyStep
		if app.pageProgress.frequency >= app.pageProgress.frequencyMax
			app.pageProgress.frequencyDirection = "down"
		if app.pageProgress.frequency <= app.pageProgress.frequencyStart
			app.pageProgress.frequencyDirection = "up"
		pageProgressElem.querySelector('.progress_filter feTurbulence').setAttribute 'baseFrequency', app.pageProgress.frequency
		return
	, app.pageProgress.tick
	document.getElementById('page-progress').classList.add 'page-progress-shown'
	return

app.HidePageProgress = ->
	document.getElementById('site').classList.remove 'progress-shown'
	document.getElementById('page-progress').classList.remove 'page-progress-shown'
	window.clearInterval app.pageProgress.animation
	return

app.UpdatePageProgress = (progressElem, percent) ->
	if progressElem && app.HasClass(progressElem, 'progress')
		percent = if percent > 100 then 100 else if percent < 0 then 0 else percent
		progressElem.querySelector('.progress_bar_inner').style.width = percent + "%"
	return

# -- Scroll-Down-Hint --
app.ScrollDownHintBinder = ->
	sdhElem = document.getElementById('content_scroll-down-hint').querySelector '.scroll-down-hint'
	if sdhElem
		app.scrollDownHint.checker = window.setInterval ->
			actualDate = new Date()
			if app.pageScroll.lastScroll
				if actualDate - app.pageScroll.lastScroll >= app.scrollDownHint.delay && !app.scrollDownHint.finished
					app.StartScrollDownHint()
			else
				app.pageScroll.lastScroll = new Date()

			if app.pageScroll.scrolled >= (app.pageScroll.maxScroll - (window.innerHeight / 2))
				app.scrollDownHint.finished = true
			else
				app.scrollDownHint.finished = false

			return
		, 1000
	document.addEventListener 'touchstart', (e) ->
		sdhElem = document.getElementById('content_scroll-down-hint').querySelector '.scroll-down-hint'
		sdhElem.classList.add 'natural-scroll'
		return
	iaElem = document.getElementById 'content_interaction-area'
	iaElem.addEventListener 'pagescroll', (e) ->
		app.StopScrollDownHint()
		return
	return

app.StartScrollDownHint = ->
	sdhElem = document.getElementById('content_scroll-down-hint').querySelector '.scroll-down-hint'
	sdhElem.classList.add 'animated'
	return

app.StopScrollDownHint = ->
	sdhElem = document.getElementById('content_scroll-down-hint').querySelector '.scroll-down-hint'
	sdhElem.classList.remove 'animated'
	return



# #########
# MOLECULES
# #########

# -- Agency Disciplines --
app.AgencyDisciplinesBinder = ->
	adElemsList = document.getElementById('overlays').querySelectorAll '.agency-disciplines'
	for adElem in adElemsList
		id = app.SetId adElem, 'ad_'
		adElem.id = id

		agencyDicipline = {
			scrollDelay: undefined,
			simpleBar: undefined
		}

		app.components[id] = agencyDicipline

		app.components[id].simpleBar = new SimpleBar(adElem.querySelector('ul'))
		app.components[id].scrollDelay = window.setInterval ->
			window.clearInterval app.components[id].scrollDelay
			app.components[id].simpleBar.getScrollElement().scrollTo {
				top: 0, 
				left: 99999,
				behavior: "smooth" 
			}
			return
		, 1000

	return

# -- Animated Agencies --
app.AnimatedAgenciesBinder = ->
	aaElemsList = document.getElementById('content').querySelectorAll '.animated-agencies'
	for aaElem in aaElemsList
		for agencyElem in aaElem.querySelectorAll '.animated-agencies_agency'
			agencyContentElem = agencyElem.querySelector('.animated-agencies_agency_content')
			agencyContentElem.style.transform = "translate3d(calc(" + app.RandomNumberFromInterval(-30,30,true) + "vw - 100%),calc(" + app.RandomNumberFromInterval(-20,20,true) + "vh - 50%),0)"
			agencyContentElem.style.transition = "margin " + app.RandomNumberFromInterval(.1,1) + "s ease"
	if aaElemsList.length > 0
		iaElem = document.getElementById 'content_interaction-area'
		iaElem.addEventListener 'pagetransform', (e) ->
			for activeAaElem in document.getElementById('content').querySelectorAll '.animated-agencies.animation-sheet.visible .animated-agencies_agency_content'
				activeAaElem.style.marginLeft = (app.pageTransform.x * (-.3)) + '%'
				activeAaElem.style.marginTop = (app.pageTransform.y * (-.3)) + '%'
			return
		
		iaElem.addEventListener 'pagescroll', (e) ->
			vh = window.innerHeight
			aas = app.activeAnimationSheet
			aasElem = document.getElementById aas.id
			isLastElem = if aas.siblingAfter then false else true

			if app.HasClass aasElem, 'animated-agencies'
				agencies = aasElem.querySelectorAll '.animated-agencies_agency'

				relativeScrolled = app.pageScroll.scrolled - (aas.passPosition * window.innerHeight)
				passDurationPerAgency = (aas.passDuration * window.innerHeight) / agencies.length
				proportionalScrolledWhole = relativeScrolled / (aas.passDuration * window.innerHeight)

				count = 0
				for agency in agencies
					proportionalScrolled = (relativeScrolled - (passDurationPerAgency * count)) / passDurationPerAgency
					
					# change transform and opacity
					if proportionalScrolled < .5
						opacity = proportionalScrolled * 2 + .1
						scale = opacity

						agency.style.transform = "scale3d(" + scale + "," + scale + "," + scale + ")"
						agency.style.opacity = opacity
					
					shrinkStart = if app.pageScroll.lingering then .8 else .5
					if proportionalScrolled >= shrinkStart
						opacity = (proportionalScrolled - 1) * -2
						scale = proportionalScrolled * 2 + ((proportionalScrolled - shrinkStart) * 2)

						agency.style.transform = "scale3d(" + scale + "," + scale + "," + scale + ")"
						agency.style.opacity = opacity
						

					# set/unset interactivity
					if proportionalScrolled >= .1 && proportionalScrolled <= .85
						agency.classList.add 'interactive'
					else
						if !isLastElem || proportionalScrolled < .1
							agency.classList.remove 'interactive'

					count++

				# set/unset interactivity
				if proportionalScrolledWhole >= .01 && proportionalScrolledWhole <= .99
					aasElem.classList.add 'interactive'
				else
					if !isLastElem || proportionalScrolledWhole < .1
						aasElem.classList.remove 'interactive'

			return
	
	return

# -- Animated Background --
app.AnimatedBackgroundBinder = ->
	for abElem in document.querySelectorAll '.animated-background'

		# switch between image sizes based on viewport width
		if window.innerWidth <= app.commonSettings.mobileBp
			imageSrc = abElem.dataset.imageMobile
		else
			imageSrc = abElem.dataset.image

		frameElem = document.createElement 'img'
		frameElem.classList.add 'animated-background_frame'
		frameElem.src = imageSrc
		frameElem.alt = "Seiten-Hintergrund"
		abElem.appendChild frameElem

	iaElem = document.getElementById 'content_interaction-area'
	iaElem.addEventListener 'pagetransform', (e) ->
		for abElem in document.querySelectorAll '.animated-background'
			frameElem = abElem.querySelector '.animated-background_frame'
			frameElem.style.marginLeft = (app.pageTransform.x * (-1)) + '%'
			frameElem.style.marginTop = (app.pageTransform.y * (-1)) + '%'
		return

	return

# -- Animated Cases --
app.AnimatedCasesBinder = ->
	for acElem in document.querySelectorAll '.animated-cases'
		caseElemsList = acElem.querySelectorAll '.animated-cases_content_cases li'

		# cirular cases presentation
		if app.HasClass acElem, 'circular-cases'
			degreeSteps = 360 / caseElemsList.length
			actualDegreeStep = 0
			for caseElem in caseElemsList
				imgRandomScale = app.RandomNumberFromInterval .3, 1
				caseElem.querySelector('a').style.transform = "translateX(-50%) scale(" + imgRandomScale + ")"
				degreeToTransform = app.DegreeToTransform(actualDegreeStep)
				caseElem.style.transform = degreeToTransform.transform
				caseElem.style.zIndex = degreeToTransform.index
				caseElem.setAttribute 'data-step', actualDegreeStep
				actualDegreeStep += degreeSteps
			id = acElem.id
			if id
				app.animationSheets[id].additionalIntervals[id + '_rotationAnimation'] = window.setInterval ->
					acElem = document.getElementById id
					if app.activeAnimationSheet.id == id && !app.HasClass(document.getElementById('site'), 'overlay-open')
						for caseElem in acElem.querySelectorAll '.animated-cases_content_cases li'
							actualDegreeStep = parseFloat caseElem.dataset.step
							actualDegreeStep += app.commonSettings.circle3dMap.settings.animationDegreeSteps
							actualDegreeStep = if actualDegreeStep >= 360 then 0 else actualDegreeStep
							degreeToTransform = app.DegreeToTransform(actualDegreeStep)
							caseElem.style.transform = degreeToTransform.transform
							caseElem.style.zIndex = degreeToTransform.index
							caseElem.dataset.step = actualDegreeStep
					return
				, 25
		# depth cases presentation
		else
			caseCount = 0
			marginLeft = -1
			marginTop = 1
			for caseElem in caseElemsList
				zDistance = if window.innerWidth <= app.commonSettings.mobileBp then 1 else 6
				caseElem.style.transform = "translate3d(-50%,-" + (caseCount * zDistance) + "vh,-" + (caseCount * zDistance) + "vh)"
				caseElem.style.zIndex = caseElemsList.length - caseCount
				marginLeft = if marginLeft < 0 then app.RandomNumberFromInterval(10,40) else app.RandomNumberFromInterval(-40,-10)
				marginTop= if marginTop < 0 then app.RandomNumberFromInterval(10,20) else app.RandomNumberFromInterval(-40,-10)
				caseElem.style.marginLeft= marginLeft + "vw"
				caseElem.style.marginTop= marginTop + "vh"
				caseCount++
			iaElem = document.getElementById 'content_interaction-area'
			iaElem.addEventListener 'pagescroll', (e) ->
				vh = window.innerHeight
				aas = app.activeAnimationSheet
				aasElem = document.getElementById aas.id
				isLastElem = if aas.siblingAfter then false else true

				if app.HasClass aasElem, 'animated-cases.depth-cases'
					cases = aasElem.querySelectorAll '.animated-cases_content_cases li'
					startEndMarginVh = 1

					relativeScrolled = app.pageScroll.scrolled - (aas.passPosition * window.innerHeight)
					
					# inside main area
					if ( relativeScrolled >= window.innerHeight * startEndMarginVh ) && ( relativeScrolled <= (aas.passDuration - startEndMarginVh) * window.innerHeight )
						# set aasElem opacity and scale to 1
						aasElem.style.opacity = 1
						aasElem.style.transform = "scale3d(1,1,1)"

						# add interactive
						aasElem.classList.add 'interactive'

						passDurationPerCase = ((aas.passDuration - (startEndMarginVh * 2)) * window.innerHeight) / cases.length
						relativeScrolledInside = (relativeScrolled - (startEndMarginVh * window.innerHeight))
						count = 0
						activeCase = 0
						for singleCase in cases
							singleCase.classList.remove 'interactive'
							# active case
							if (relativeScrolledInside > (count * passDurationPerCase)) && ((count * passDurationPerCase) + passDurationPerCase) > relativeScrolledInside
								singleCase.classList.add 'interactive'
								proportionalScrolled = (relativeScrolledInside - (count * passDurationPerCase)) / passDurationPerCase
								
								caseTransform = proportionalScrolled * 20
								zDistance = if window.innerWidth <= app.commonSettings.mobileBp then 1 else 6
								singleCase.style.transform = "translate3d(-50%," + ((-count * zDistance) + caseTransform) + "vh," + ((-count * zDistance) + caseTransform) + "vh)"

								# fade Out
								if proportionalScrolled >= .8
									caseOpacity = 1 - ((proportionalScrolled - .8) * 5)
									singleCase.style.opacity = caseOpacity
									singleCase.classList.remove 'interactive'
									

								zDistance = if window.innerWidth <= app.commonSettings.mobileBp then .5 else 2
								masterTransform = zDistance * (count + proportionalScrolled)
								masterTransform = if masterTransform < 0 then 0 else masterTransform
								aasElem.querySelector('.animated-cases_content_cases').style.transform = "translate3d(0," + masterTransform + "vh," + masterTransform + "vh)"
	
							if ((count * passDurationPerCase) + passDurationPerCase) <= relativeScrolledInside
								singleCase.style.opacity = 0
							if (relativeScrolledInside <= (count * passDurationPerCase))
								singleCase.style.opacity = 1
				
							count++


					# outside main area
					else
						if ( relativeScrolled < window.innerHeight * startEndMarginVh )
							proportionalScrolledOutside = (relativeScrolled / (startEndMarginVh * window.innerHeight))
						else
							proportionalScrolledOutside = 1 - ((relativeScrolled - ((aas.passDuration - startEndMarginVh) * window.innerHeight)) / (startEndMarginVh * window.innerHeight))
						
						opacity = proportionalScrolledOutside
						if ( relativeScrolled > (aas.passDuration - startEndMarginVh) * window.innerHeight )
							scale = (1 - proportionalScrolledOutside) * 3
							scale = if scale < 1 then 1 else scale
						else
							scale = proportionalScrolledOutside
							scale= if app.HasClass(aasElem, 'blend-element') then scale + .1 else scale
							opacity = if app.HasClass(aasElem, 'blend-element') then opacity + .1 else opacity
						aasElem.style.opacity = opacity
						aasElem.style.transform = "scale3d(" + scale + "," + scale + "," + scale + ")"

						# remove interactive
						if !isLastElem
							aasElem.classList.remove 'interactive'
							
						else if relativeScrolled < window.innerHeight * startEndMarginVh
							aasElem.classList.remove 'interactive'


				return
	return

# -- Animated Headline Motive --
app.AnimatedHeadlineMotiveBinder = ->
	ahElemsList = document.querySelectorAll '.animated-headline-motive'
	for ahmElem in ahElemsList
		motiveElem = ahmElem.querySelector('.animated-headline-motive_content_motive')
		# switch between image sizes based on viewport width
		if window.innerWidth <= app.commonSettings.mobileBp
			imageSrc = motiveElem.dataset.imageMobile
		else
			imageSrc = motiveElem.dataset.image
		imageElem = document.createElement 'img'
		imageElem.src = imageSrc
		imageElem.alt = "Motiv"
		motiveElem.appendChild imageElem

		textElem = ahmElem.querySelector '.animated-headline-motive_content_text'

		motiveElem.style.transition = "margin " + app.RandomNumberFromInterval(.1,.5) + "s ease"
		textElem.style.transition = "margin " + app.RandomNumberFromInterval(.1,.5) + "s ease"
	
	if ahElemsList.length > 0
		iaElem = document.getElementById 'content_interaction-area'
		iaElem.addEventListener 'pagetransform', (e) ->
			for ahElem in document.querySelectorAll '.animated-headline-motive.visible'
				motiveElem = ahmElem.querySelector('.animated-headline-motive_content_motive')
				textElem = ahmElem.querySelector '.animated-headline-motive_content_text'

				motiveElem.style.marginLeft = (app.pageTransform.x * (-.3)) + '%'
				motiveElem.style.marginTop = (app.pageTransform.y * (-.3)) + '%'

				textElem.style.marginLeft = (app.pageTransform.x * (-1)) + '%'
				textElem.style.marginTop = (app.pageTransform.y * (-1)) + '%'
			return

	return

# -- Form Builder --
app.FormBuilderBinder = (onlyOverlay) ->
	if onlyOverlay
		fbElemsList = document.getElementById('overlays').querySelectorAll('.form-builder')
	else
		fbElemsList = document.querySelectorAll '.form-builder'
	for fbElem in fbElemsList
		fbElem.addEventListener 'submit', (e) ->
			e.preventDefault()
			validForm = true
			for frElem in this.querySelectorAll '.form-row'
				for inputElem in frElem.querySelectorAll 'input'
					app.HideElementError inputElem
					if inputElem.hasAttribute 'required'
						if inputElem.type == "checkbox"
							if !inputElem.checked
								validForm = false
								app.ShowElementError inputElem, 'Dieses Feld muss ausgewählt werden.'
						else if inputElem.type == "file"
							if inputElem.value == ""
								validForm = false
								app.ShowElementError inputElem, "Es muss (mindestens) eine Datei hinzugefügt werden."
						else if inputElem.type != "hidden"
							if inputElem.value == ""
								validForm = false
								app.ShowElementError inputElem, "Dieses Feld muss ausgefüllt werden."
				for selectElem in frElem.querySelectorAll '.select'
					app.HideElementError selectElem
					if app.HasClass selectElem, 'required'
						if selectElem.querySelector('input[type=hidden]').value == ""
							validForm = false
							app.ShowElementError selectElem, "Hier muss eine Option gewählt werden."
				for radioGroupElem in frElem.querySelectorAll '.radio-group'
					app.HideElementError radioGroupElem
					if app.HasClass radioGroupElem, 'required'
						if !radioGroupElem.querySelector('input[type=radio]:checked')
							validForm = false
							app.ShowElementError radioGroupElem, "Hier muss eine Option gewählt werden."
				for textareaElem in frElem.querySelectorAll 'span.textarea'
					app.HideElementError textareaElem
					if textareaElem.closest('.input').querySelector('textarea').hasAttribute 'required'
						if textareaElem.innerText == ""
							validForm = false
							app.ShowElementError textareaElem, 'Dieses Feld muss ausgefüllt werden.'
			if validForm
				app.ShowFormBuilderProcessing this
				if app.HasClass this, 'ajax-form'
					app.FormBuilderAjaxFormSubmit this
				else
					this.submit()
			else
				app.ShowFormMessage this, "Das Formular wurde nicht korrekt ausgefüllt.<br/>Bitte überprüfen Sie Ihre Eingaben.", 'error'
		
		fbElem.addEventListener 'reset', (e) ->
			for inputElem in this.querySelectorAll('input')
				inputElem.classList.remove 'filled'
				app.HideElementError inputElem
			for fakeInputElem in this.querySelectorAll('.input_input')
				fakeInputElem.innerText = ""
				app.HideElementError fakeInputElem
			for selectElem in this.querySelectorAll '.select'
				selectElem.querySelector('.select_box_title').innerHTML = selectElem.dataset.title
				selectElem.querySelector('input[type=hidden]').value = ""
				app.HideElementError selectElem
			for textareaElem in this.querySelectorAll 'span.textarea'
				textareaElem.innerText = ""
				textareaElem.closest('.input').querySelector('textarea').value = ""
				textareaElem.classList.remove 'filled'
				app.HideElementError textareaElem
			return

		fbElem.querySelector('.form-builder_content_message_container_button .btn').addEventListener 'click', (e) ->
			e.preventDefault()
			app.HideFormMessage this.closest('.form-builder')
			this.innerHTML = "Ok"
			return

	return
app.ShowFormBuilderProcessing = (fbElem) ->
	if fbElem
		fbElem.classList.add 'processing'
	return
app.HideFormBuilderProcessing = (fbElem) ->
	if fbElem
		fbElem.classList.remove 'processing'
	return
app.ShowFormMessage = (fbElem, messageText, type) ->
	if fbElem
		fbElem.querySelector('.form-builder_content_message_container_text').innerHTML = messageText
		fbElem.classList.add 'message'
		if type == 'error'
			fbElem.classList.add 'form-error'
		fbElem.querySelector('.form-builder_content_message_container_text').scrollIntoView()
	return
app.HideFormMessage = (fbElem) ->
	if fbElem
		fbElem.querySelector('.form-builder_content_message_container_text').innerHTML = ""
		fbElem.classList.remove 'message'
		fbElem.classList.remove 'form-error'
	return
app.FormBuilderAjaxFormSubmit = (fbElem) ->
	if fbElem
		formData = new FormData(fbElem)

		ajaxFormRequest = new XMLHttpRequest()
		ajaxFormRequest.onreadystatechange = ->
			if ajaxFormRequest.readyState == 4
				if ajaxFormRequest.status == 200
					ajaxFormResponse = JSON.parse ajaxFormRequest.responseText
					app.HideFormBuilderProcessing fbElem
					if ajaxFormResponse.success
						if fbElem.dataset.successButtontext && fbElem.dataset.successButtontext != ""
							fbElem.querySelector('.form-builder_content_message_container_button .btn').innerHTML = fbElem.dataset.successButtontext
						app.ShowFormMessage fbElem, if (fbElem.dataset.successText && fbElem.dataset.successText != "") then fbElem.dataset.successText else ajaxFormResponse.message
						fbElem.reset()
					else
						app.ShowFormMessage fbElem, ajaxFormResponse.message, 'error'
				else
					app.HideFormBuilderProcessing fbElem
					app.ShowFormMessage fbElem, "Beim Absenden des Formulars gab es leider ein Problem.", 'error'
		ajaxFormRequest.open "POST", fbElem.getAttribute('action')
		ajaxFormRequest.send(formData)
	return

# -- Nav-List --
app.NavListBinder = ->
	for navList in document.querySelectorAll '.nav-list'
		for navLink in navList.querySelectorAll 'a'
			# blur nav links on click to remove assistant outlining
			navLink.addEventListener 'click', (e) ->
				this.blur()
				return
	return



# #########
# ORGANISMS
# #########

# -- Agency Contact Form --
app.AgencyContactFormBinder = ->
	for acfElem in document.getElementById('overlays').querySelectorAll '.agency-contact-form'
		for agencyChooseElem in acfElem.querySelectorAll '.radio-group[data-name="agency"] input[type="radio"]'
			agencyChooseElem.addEventListener 'change', (e) ->
				if this.checked
					privacyCheckboxElem = this.closest('.form-builder').querySelector('.checkbox[data-name="privacy"]')
					agencyPrivacyUrl = this.closest('label').dataset.privacyUrl
					commonPrivacyUrl = privacyCheckboxElem.dataset.privacyUrl
					if agencyPrivacyUrl && agencyPrivacyUrl != ""
						privacyCheckboxElem.querySelector('a').href = agencyPrivacyUrl
					else
						privacyCheckboxElem.querySelector('a').href = commonPrivacyUrl
				return

	return

# -- Case Detail --
app.CaseDetailBinder = ->
	for cdElem in document.getElementById('overlays').querySelectorAll '.case-detail'
		splideElem = cdElem.querySelector '.splide'
		if splideElem
			new Splide(splideElem, {
				arrows:		false,
				autoplay: 	true,
				interval:	3000,
				pagination:	true,
				perPage: 	1,
				type:		'loop'
			}).mount()
	return

# -- Logo --
app.LogoBinder = ->
	mainLogoElem = document.getElementById 'logo'
	if mainLogoElem
		mainLogoElem.addEventListener 'click', (e) ->
			e.preventDefault()
			app.ScrollToAnimationSheet Object.keys(app.animationSheets)[0]
			return
	return

# -- Main Nav --
app.MainNavBinder = ->
	for mnElem in document.querySelectorAll '.main-nav'
		mnElem.querySelector('.main-nav_mobile-menu').addEventListener 'click', (e) ->
			e.preventDefault()
			mnElem = this.closest('.main-nav')
			mnElem.classList.toggle 'menu-open'
			if app.HasClass mnElem, 'menu-open'
				document.getElementById('site').classList.add 'main-menu-open'
			else
				document.getElementById('site').classList.remove 'main-menu-open'
			return
	return

# -- Overlay --
app.OverlayBinder = ->
	document.getElementById('overlays_closer').addEventListener 'click', (e) ->
		e.preventDefault()
		if app.overlay.overwritten.type && app.overlay.overwritten.id
			if app.overlay.overwritten.type == 'overlay'
				app.GetContentAndOpenOverlay app.overlay.overwritten.id
			# look for agency overlays to open at start
			if app.overlay.overwritten.type == 'agency'
				app.GetAgencyDetailAndOpenOverlay app.overlay.overwritten.id

			# look for case overlays to open at start
			if app.overlay.overwritten.type == 'case'
				app.GetCaseDetailAndOpenOverlay app.overlay.overwritten.id
			app.overlay.overwritten.id = undefined
			app.overlay.overwritten.type = undefined
		else
			app.CloseOverlay()
		return
	return
app.CloseOverlay = ->
	document.getElementById('site').classList.remove 'overlay-open'

	params = app.CheckURLParams()
	if params
		# delete overlay param
		if params.hasOwnProperty('overlay')
			url = new URL(window.location.href)
			url.searchParams.delete('overlay')
			window.history.pushState document.getElementById('site').innerHTML, document.title, url

		# delete agency param if overlay was agency overlay
		if params.hasOwnProperty('agency')
			url = new URL(window.location.href)
			url.searchParams.delete('agency')
			window.history.pushState document.getElementById('site').innerHTML, document.title, url

		# delete case param if overlay was case overlay
		if params.hasOwnProperty('case')
			url = new URL(window.location.href)
			url.searchParams.delete('case')
			window.history.pushState document.getElementById('site').innerHTML, document.title, url
	return

app.OpenOverlay = (content, paddingLess, appendFunction) ->
	document.getElementById('overlays_closer').classList.remove 'back-button'
	if app.overlay.overwritten.type && app.overlay.overwritten.id
		document.getElementById('overlays_closer').classList.add 'back-button'
	overlay = document.getElementById('overlays')
	overlayContent = overlay.querySelector('.overlay_content')
	overlayContent.classList.remove 'paddingless'
	if paddingLess
		overlayContent.classList.add 'paddingless'
	overlayContent.innerHTML = content
	document.getElementById('site').classList.add 'overlay-open'
	# reset scroll
	document.getElementById('overlays').querySelector('.overlay').scroll(0,0)

	# Binder
	app.CheckboxBinder true
	app.ImageBinder true
	app.InputBinder true
	app.RadioGroupBinder true
	app.SelectBinder true

	app.AgencyDisciplinesBinder()
	app.FormBuilderBinder true

	app.CaseDetailBinder()

	app.AgencyContactFormBinder()
	app.OverlayOpenerBinder true

	# call appended function
	if appendFunction
		appendFunction()

	return

app.GetAgencyDetailAndOpenOverlay = (agencyId) ->
	app.ShowPageLoader()
	app.CloseOverlay()
	agencyDetailRequest = new XMLHttpRequest()
	agencyDetailRequest.onreadystatechange = ->
		if agencyDetailRequest.readyState == 4
			if agencyDetailRequest.status == 200
				if agencyDetailRequest.responseText && agencyDetailRequest.responseText != ""
					url = new URL(window.location.protocol + "//" + window.location.host)
					url.searchParams.append 'agency', agencyId
					url.searchParams.set 'agency', agencyId
					window.history.pushState document.getElementById('site').innerHTML, document.title, url
					app.OpenOverlay agencyDetailRequest.responseText
				app.HidePageLoader()
			else
				app.HidePageLoader()
				console.log "Es ist ein Fehler aufgetreten: " + overlayContentRequest.status
	agencyDetailRequest.open "GET", window.location.protocol + "//" + window.location.host + "/agency/" + agencyId, true
	agencyDetailRequest.send()
	return

app.GetCaseDetailAndOpenOverlay = (caseId) ->
	app.ShowPageLoader()
	app.CloseOverlay()
	caseDetailRequest = new XMLHttpRequest()
	caseDetailRequest.onreadystatechange = ->
		if caseDetailRequest.readyState == 4
			if caseDetailRequest.status == 200
				if caseDetailRequest.responseText && caseDetailRequest.responseText != ""
					url = new URL(window.location.protocol + "//" + window.location.host)
					url.searchParams.append 'case', caseId
					url.searchParams.set 'case', caseId
					window.history.pushState document.getElementById('site').innerHTML, document.title, url
					app.OpenOverlay caseDetailRequest.responseText, true
				app.HidePageLoader()
			else
				app.HidePageLoader()
				console.log "Es ist ein Fehler aufgetreten: " + overlayContentRequest.status
	caseDetailRequest.open "GET", window.location.protocol + "//" + window.location.host + "/case/" + caseId, true
	caseDetailRequest.send()
	return

app.GetContentAndOpenOverlay = (overlayId) ->
	app.ShowPageLoader()
	overlayContentRequest = new XMLHttpRequest()
	overlayContentRequest.onreadystatechange = ->
		if overlayContentRequest.readyState == 4
			if overlayContentRequest.status == 200
				if overlayContentRequest.responseText && overlayContentRequest.responseText != ""
					url = new URL(window.location.protocol + "//" + window.location.host)
					url.searchParams.append 'overlay', overlayId
					url.searchParams.set 'overlay', overlayId
					window.history.pushState document.getElementById('site').innerHTML, document.title, url
					app.OpenOverlay overlayContentRequest.responseText
				app.HidePageLoader()
			else
				app.HidePageLoader()
				console.log "Es ist ein Fehler aufgetreten: " + overlayContentRequest.status
	overlayContentRequest.open "GET", window.location.protocol + "//" + window.location.host + "/overlay/" + overlayId, true
	overlayContentRequest.send()
	return


# #########
# MAIN
# #########

app.Main = ->
	# ### COMMON
	app.AnimationSheetsBinder()
	app.CheckForAutoOpenOverlay()
	app.InteractionAreaBinder()
	app.OverlayOpenerBinder()
	app.SoundButtonBinder()
	app.WindowHistoryBinder()

	# ### ATOMS
	app.AnimationAssetsBinder()
	app.CheckboxBinder()
	app.ImageBinder()
	app.InputBinder()
	app.RadioGroupBinder()
	app.SelectBinder()
	app.ScrollDownHintBinder()

	# ### MOLECULES
	app.AnimatedAgenciesBinder()
	app.AnimatedBackgroundBinder()
	app.AnimatedCasesBinder()
	app.AnimatedHeadlineMotiveBinder()
	app.FormBuilderBinder()
	app.NavListBinder()


	# ### ORGANISMS
	app.LogoBinder()
	app.MainNavBinder()
	app.OverlayBinder()


	app.InitPage()

	return

app.Main()